import React from 'react';
import './ckeditor.css';
import { connect } from "react-redux";
import "../vendor/perfect-scrollbar.css";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

//import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices.js';
import Comments from '@ckeditor/ckeditor5-comments/src/comments.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import ListProperties from '@ckeditor/ckeditor5-list/src/listproperties';
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript.js';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';
import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount.js';

import Placeholder from './Placeholder';
import CommentsIntegration from './CommentsIntegration';

const commonPlugins = [
	Alignment,
	Autoformat,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	CloudServices,
	Essentials,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	Heading,
	HorizontalLine,
	Image,
	ImageCaption,
	ImageResize,
	ImageStyle,
	ImageToolbar,
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	List,
	ListProperties,
	PageBreak,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCellProperties,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	WordCount
];

const documentPlugins = [
	Placeholder,
	Comments,
	CommentsIntegration
];

const toolbar = [
	'comment',
	'heading',
	'|',
	'fontSize',
	'fontFamily',
	'|',
	'fontColor',
	'fontBackgroundColor',
	'|',
	'bold',
	'italic',
	'underline',
	'strikethrough',
	'|',
	'alignment',
	'|',
	'numberedList',
	'bulletedList',
	'|',
	'outdent',
	'indent',
	'|',
	'todoList',
	'link',
	'blockQuote',
	'imageUpload',
	'insertTable',
	'|',
	'undo',
	'redo',
	'horizontalLine',
	'pageBreak',
	'superscript',
	'subscript',
	'removeFormat'
];

const MyCKEditor = ({ setEditor, onChange, initialData, type, contained, users }) => {

	const colors = [
		{ color: '#000000', label: 'Black' },
		{ color: '#4D4D4D', label: 'Dim grey' },
		{ color: '#999999', label: 'Grey' },
		{ color: '#E6E6E6', label: 'Light grey' },
		{ color: '#FFFFFF', label: 'White' },
		{ color: '#E64C4C', label: 'Red' },
		{ color: '#E6994C', label: 'Orange' },
		{ color: '#E6E64C', label: 'Yellow' },
		{ color: '#99E64C', label: 'Light Green' },
		{ color: '#4CE64C', label: 'Green' },
		{ color: '#4CE699', label: 'Aquamarine' },
		{ color: '#4CE6E6', label: 'Turquoise' },
		{ color: '#4C99E6', label: 'Light blue' },
		{ color: '#4C4CE6', label: 'Blue' },
		{ color: '#994CE6', label: 'Purple' }
	];

	const plugins = [...commonPlugins];

	switch (type) {
		case "document":
			plugins.push(...documentPlugins);
			break;
	}

	const editorConfiguration = {
		plugins: plugins,
		licenseKey: 'upOSgYiWLBkSU4Z8M9ev18F4TZhOll1FK412R6zkEkdYRmFtfxuEM0sZP2wenv6FxqcwDQc+UfTGQQ==',
		toolbar: toolbar,
		table: {
			contentToolbar: ['tableProperties', 'tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties']
		},
		comments: {
			editorConfig: {}
		},
		fontColor: { colors },
		fontBackgroundColor: { colors },
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		exportPdf: {
			converterUrl: 'https://ckeditor-pdf.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert',
			stylesheets: [
				'EDITOR_STYLES'			
			],
			converterOptions: {
				margin_top: '15mm',
				margin_bottom: '15mm',
				margin_right: '15mm',
				margin_left: '15mm',
				header_html: '<div class="styled">Header content</div>',
				footer_html: '<div class="styled-counter"><span class="pageNumber"></span></div>',
				header_and_footer_css: '#header, #footer { background: hsl(0, 0%, 95%); } .styled { font-weight: bold; text-align: center; } .styled-counter { font-size: 1em; color: hsl(0, 0%, 60%); }',
			}
		},
		exportWord: {
			converterUrl: 'https://ckeditor-docx.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert',
			fileName: 'my-file.docx',
			converterOptions: {
				format: 'A4', // Default value, you don't need to specify it explicitly for A4.
				margin_top: '20mm',
				margin_bottom: '20mm',
				margin_right: '12mm',
				margin_left: '12mm'
			}
		}
	};

	if (!users)
		return null;
	
	return (
		<CKEditor
			editor={ClassicEditor}
			config={editorConfiguration}
			data={initialData}
			onReady={editor => {					

				setEditor && setEditor(editor);				

				//CKEditorInspector.attach('editor', editor);

				if (contained) { 
					editor.editing.view.change((writer) => {

						writer.setStyle(
							"height",
							"0",
							editor.editing.view.document.getRoot()
						);
					});
				}

				if (type === "plain") {
					editor.editing.view.change((writer) => {

						writer.setStyle(
							"min-height",
							"200px",
							editor.editing.view.document.getRoot()
						);
					});
				}
				
			}}
			onBlur={(event, editor) => {
				const data = editor.getData();
				onChange && onChange(data);
			}}
			onError={error => {
				var propertyNames = Object.getOwnPropertyNames(error);
				var descriptor;
				for (var property, i = 0, len = propertyNames.length; i < len; ++i) {
					property = propertyNames[i];
					descriptor = Object.getOwnPropertyDescriptor(error, property);
					console.log('error', property, descriptor);
				}
			}}
		/>

	);
}

const mapStateToProps = state => ({ mentions: state.configReducer.mentions, users: state.userReducer.users });

export default connect(mapStateToProps)(MyCKEditor);
